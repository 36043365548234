<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">宣传教育活动照片</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="活动名称"
                    prop="XCJYQKJLID"
                    class="from-item-block"
                >
                    <el-select
                        id="ycys"
                        v-model="formdata.dataDic.XCJYQKJLID"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in HDList"
                            :key="`${index}`"
                            :value="item.Id"
                            :label="item.Hdmc"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="SJ" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.SJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="现场情况描述" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.XCQKMS"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="现场照片" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xcjyhdzp",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "182",
                dataDic: {
                    XCJYQKJLID: "", // 活动名称
                    SJ: "", // 时间
                    XCQKMS: "", // 现场情况描述
                },
            },
            rules: {
                XCJYQKJLID: [
                    {
                        required: true,
                        message: "请选择活动名称",
                        trigger: "change",
                    },
                ],
                SJ: [
                    {
                        required: true,
                        message: "请选择时间",
                        trigger: "change",
                    },
                ],
                // YCSSCDVALUE: [
                //     { required: true, message: '请选择遗产受损程度', trigger: 'blur' }
                // ]
            },
            YCSSCDList: [],
            SJLXList: [],
            showQTSJXLX: false,
            HDList: [], // 宣传列表
        };
    },
    mounted() {
        this.GetEnum();
        this.xcList();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID", "GetXcjyList"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1005",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "SJLXVALUE") {
                    this.SJLXList = v.dataItems;
                }
                if (v.COLUMNID === "YCSSCDVALUE") {
                    this.YCSSCDList = v.dataItems;
                }
            });
        },
        // 获得宣传列表
        async xcList() {
            let res = await this.GetXcjyList({});
            if (res.IsSuccess) {
                this.HDList = res.ResultValue;
            }
            console.log(res, "获得宣传列表");
        },
    },
};
</script>

<style></style>
